<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-overlay>
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form-group
              label="Select Startup"
              label-class="h5"
            >
              <v-select
                id="selectProgram"
                v-model="selectProgram"
                :close-on-select="true"
                :get-option-label="option => option.programs_applicantstable.users_organizationtable.title"
                :options="programs"
                :reduce="option => option.id"
                placeholder="Choose Startup"
                @input="$router.replace(`/startup-portal/programs/enter/${$route.params.id}/${selectProgram}`)"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <section class="grid-view">
      <b-card
        v-for="(application, i) in applications"
        :key="i"
        :img-src="require('@/assets/images/slider/06.jpg')"
        :title="application.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-title>{{ application.title }}</b-card-title>
          <b-card-text>
            <br> Round Number : {{ application.round_number }}
            <br> Status : {{ application.status }}
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            v-if="application.review_status==='disabled'"
            v-b-modal.Nodata
            class="btn btn-wishlist btn-light"
            variant="primary"
          >
            <span>Report</span>
          </b-link>
          <b-link
            v-else
            v-b-modal.selectStartup
            class="btn btn-wishlist btn-light"
            variant="primary"
          >
            <span>Report</span>
          </b-link>
          <b-link
            :to="`/startup-portal/application/${$route.params.id}/${application.id}`"
            class="btn btn-primary btn-cart"
          >
            <span>Submit / View</span>
          </b-link>
        </div>
      </b-card>
    </section>
    <b-modal
      id="selectStartup"
      ok-only
      ok-title="OK"
      size="lg"
      title="Report"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="No report available"
            label-size="m"
          >
            <b-form-input
              v-model="selectProgram"
              placeholder="Select Startup"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label=""
            label-size="sm"
          >
            <treeselect
              v-model="selectedOperation"
              :multiple="true"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BCardTitle, BOverlay, BLink, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BOverlay,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      selectStartup: Number.parseInt(this.$route.params.aid, 10),
      programs: [],
      applications: [],
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  apollo: {
    programs: {
      query() {
        return gql`
        {
          programs_startupparticipants(where: {programs_applicantstable: {organization_id_id: {_in: [${this.userOrgs}]},program_id: {_eq: ${this.$route.params.id}}}}) {
            id
            programs_applicantstable {
              users_organizationtable {
                title
              }
            }
          }
        }`
      },
      update(data) {
        return data.programs_startupparticipants
      },
    },

  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
